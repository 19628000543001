import React from "react";
import classNames from "classnames";
import { Container, Text, Button, AnimateIn } from "@atoms";

const ColumnCopy = ({
  buttons,
  copy,
  descriptor,
  heading,
  paddingBottom,
  showDivider,
}) => {
  return (
    <section
      className={classNames("pt-20 md:pt-28", {
        "border-t-2 border-black": showDivider,
        "pb-20 md:pb-28": paddingBottom,
      })}
    >
      <Container>
        {(heading || descriptor) && (
          <AnimateIn
            preset="fadeUpFast"
            className="flex flex-wrap justify-between gap-6 md:gap-20"
          >
            {heading && (
              <div
                className={classNames("flex-[1_1_100%]", {
                  "md:flex-[0_1_50%] lg:flex-[0_1_66.66%]": descriptor,
                })}
              >
                <Text variant="h2" className="md:max-w-[16ch]">
                  {heading}
                </Text>
              </div>
            )}
            {descriptor && (
              <div className="md:flex-1">
                <Text className="font-bold leading-normal md:text-right">
                  {descriptor}
                </Text>
              </div>
            )}
          </AnimateIn>
        )}
        {(copy || !!buttons?.length) && (
          <AnimateIn preset="fadeUpFast" threshold={0.4}>
            {copy && (
              <div className="mt-12 columns-1 gap-12 md:columns-2">
                <Text variant="body" richText className="break-inside-auto">
                  {copy}
                </Text>
              </div>
            )}
            {!!buttons?.length && (
              <div className="mt-12 flex flex-wrap gap-8 md:mt-16 md:gap-16">
                {buttons.map(button => {
                  const { uid, link, download } = button;
                  return (
                    <div key={uid} className="w-full md:w-auto">
                      <Button to={link.url} download={download}>
                        {link.text}
                      </Button>
                    </div>
                  );
                })}
              </div>
            )}
          </AnimateIn>
        )}
      </Container>
    </section>
  );
};

ColumnCopy.defaultProps = {
  paddingBottom: true,
};

export default ColumnCopy;
